<template>
  <div :class="['py-md-5', 'h-100', { 'bg-white': isMobile }]">
    <div
      :class="isDesktop ? 'back-link mb-1 px-4 px-md-0' : 'fz-20 font-weight-regular pt-3 pb-7 white black--text'"
      :style="!isDesktop ? 'line-height:34px' : ''"
      class="dark-text py-0 px-4 px-md-0 d-flex"
    >
      <router-link
        to="/"
        class="back-link"
      >
        <v-icon size="26px" class="mr-1">
          navigate_before
        </v-icon>
        <span class="back-link px-3 px-md-0 d-none d-md-block">Switch Account</span>
      </router-link>
      <v-spacer />
    </div>
    <v-card
      :loading="loading"
      style="box-shadow: unset; margin-top: 24px;"
      class=""
      elevation="0"
    >
      <v-list class="py-0">
        <template v-for="(item, itemIndex) in accounts">
          <v-divider
              v-if="itemIndex"
              :key="itemIndex + 'divider'"
          ></v-divider>
          <v-list-item
              :key="itemIndex"
              :class="{expanded:item.expand}"
              class="px-5"
              :disabled="currentAccountUuid === item.acco_uuid"
              @click.stop.prevent="switchAccountMethod(item)"
          >
            <v-list-item-content>
              <v-list-item-title class="fz-14 font-weight-semi-bold">
                <span class="py-4 pr-4">{{ item.acco_name }}</span>
                <span v-if="currentAccountUuid === item.acco_uuid" class="font-weight-light">Current Account</span>
              </v-list-item-title>

              <v-list-item-subtitle class="fz-14">
                <span>{{ item.web_url }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>

          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SwitchAccount',
  data: () => ({
    loading: false
  }),
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.prevPage = from.path
    })
  },
  computed: {
    auth () {
      return this.$store.state.auth
    },
    currentAccountUuid () {
      return this.auth.currentUser?.accountUuid
    },
    accounts () {
      return this.$store.state.accounts.accounts || []
    },
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  created () {
    this.getData()
  },
  methods: {
    ...mapActions([
      'getAccounts',
      'switchAccount'
    ]),
    async getData () {
      try {
        this.loading = true
        await this.getAccounts()
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    async switchAccountMethod (user) {
      try {
        this.loading = true
        const { data } = await this.switchAccount(user.acco_uuid)
        const query = {
          token: data.user.token,
          role: data.user.role
        }
        localStorage.clear()
        const _query = encodeURIComponent(btoa(JSON.stringify(query)))
        window.location.replace('https://' + user.web_url + `/#/login?user=${_query}`)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.card-header  {
  position: relative;
  &::after {
    content: '';
    height: 1px;
    left: -20px;
    bottom: -10px;
    position: absolute;
    width: calc(100% + 40px);
    border-bottom: 1px solid #C7C7C7;
  }
}
</style>
