<template>
  <div :class="['py-md-5', 'h-100', { 'bg-white': isMobile }]">
    <div
      :class="isDesktop ? 'back-link mb-1 px-4 px-md-0' : 'fz-20 font-weight-regular pt-3 pb-7 white black--text'"
      :style="!isDesktop ? 'line-height:34px' : ''"
      class="dark-text py-0 px-4 px-md-0 d-flex align-center"
    >
      <router-link
        to="/"
        class="back-link"
      >
        <v-icon size="26px" class="mr-1">
          navigate_before
        </v-icon>
        <span class="back-link px-3 px-md-0 d-none d-md-block">My Profile</span>
      </router-link>
      <v-spacer />
      <v-btn
        v-if="!isDesktop"
        to="/edit-profile"
        class="text-none fz-16 px-0"
        color="#A7A5B1"
        text
      >
        Edit
      </v-btn>
    </div>
    <h1 v-if="isDesktop" class="dark-text font-weight-semi-bold fz-28 px-4 px-md-0">{{ fullName }}</h1>

    <div v-if="isDesktop" class="mb-4 px-4 px-md-0 d-flex">
      <v-btn to="/edit-profile" text class="text-none d-flex align-center px-2" height="36px">
        <v-icon size="24px" color="#919EAB" class="mr-1">edit</v-icon>
        Edit Profile
      </v-btn>

      <v-btn to="/settings" text class="text-none ml-3 d-flex align-center px-2" height="36px">
        <v-icon size="24px" color="#919EAB" class="mr-1">settings</v-icon>
        Settings
      </v-btn>
    </div>
    <v-card
      :loading="loading"
      style="box-shadow: unset;"
      class="px-3"
      elevation="0"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="px-0 pt-0"
        >
          <v-card
            class="pa-5 fz-14 overflow-hidden"
            elevation="0"
          >
            <v-form
              v-model="valid"
            >
              <v-row class="ma-0 card-header">
                <v-col
                  cols="12"
                  md="12"
                  class="d-flex py-0"
                >
                  <v-list-item-avatar width="60px" height="60px" class="mr-5">
                    <v-img
                      :src="form.link"
                      :alt="`${form.firstname} avatar`"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content class="d-block">
                    <v-list-item-title class="fz-16 font-weight-semi-bold">{{ fullName }}</v-list-item-title>

                    <v-list-item-subtitle class="py-1">{{ form.text }}</v-list-item-subtitle>

                    <v-list-item-subtitle>{{ form.country }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action v-if="isDesktop">
                    <v-btn
                      class="grey-button"
                      to="/edit-profile"
                    >
                      <v-icon size="20px" color="#919EAB" class="mr-2">edit</v-icon>
                      Edit
                    </v-btn>
                  </v-list-item-action>
                </v-col>
              </v-row>

              <v-row class="mt-1">
                <v-col
                  cols="12"
                  md="12"
                  class="pb-1"
                >
                  <div
                    :style="{ color: isDesktop ? '#212B36' : '#a7a5b1' }"
                    :class="isDesktop ? 'text-uppercase fz-12 line-h-16 font-weight-semi-bold mt-2' : 'fz-16 font-weight-medium'"
                    class="pa-0"
                  >Contacts</div>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                  class="pb-4 pt-4 px-0 d-flex align-start"
                  v-for="(phone, name) in form.contacts"
                  :key="name"
                >
                  <div class="d-flex align-baseline px-3">

                      <v-icon color="#919EAB" class="mr-4">
                        {{ name }}
                      </v-icon>

                      <v-list-item-content class="py-1">
                        <v-list-item-title class="text-capitalize fz-14 mb-2 d-flex justify-space-between">
                          {{ name }}
                        </v-list-item-title>

                        <v-list-item-subtitle
                          v-for="(item, index) in phone"
                          :key="index"
                        >
                          <v-col
                            cols="12"
                            md="12"
                            class="py-4 px-0"
                            v-if="index !== 0"
                          ></v-col>

                          <v-col
                            cols="12"
                            md="12"
                            class="pa-0 fz-16 font-weight-bold"
                          >
                            {{ item.name }}
                          </v-col>

<!--                          <v-col-->
<!--                            cols="12"-->
<!--                            md="12"-->
<!--                            class="pa-0 pr-2 d-flex flex-wrap"-->
<!--                            v-for="(extension, extensionIndex) in item"-->
<!--                            :key="extensionIndex"-->
<!--                          >-->
<!--                            <template-->
<!--                              v-if="extensionIndex === 'extensions' && $store.state.auth.currentUser.role !== 'company_admin'"-->
<!--                            >-->
<!--                              <v-list-item-title class="text-capitalize fz-14">-->
<!--                                {{ extensionIndex }}-->
<!--                              </v-list-item-title>-->

<!--                              <v-chip-->
<!--                                class="tags mr-2 mt-2"-->
<!--                                v-for="(chip, chipIndex) in extension"-->
<!--                                :key="chipIndex"-->
<!--                              >-->
<!--                                <v-icon-->
<!--                                  size="25px"-->
<!--                                  color="#5C6AC4"-->
<!--                                  class="mr-2"-->
<!--                                  v-if="$store.state.auth.currentUser.role !== 'company_admin'"-->
<!--                                >link-->
<!--                                </v-icon>-->

<!--                                {{ chip }}-->
<!--                              </v-chip>-->

<!--                              <v-btn-->
<!--                                to="/assign-number"-->
<!--                                class="text-capitalize px-1 mt-2"-->
<!--                                text-->
<!--                                height="32px"-->
<!--                                v-if="$store.state.auth.currentUser.role === 'company_admin'"-->
<!--                              >-->
<!--                                <v-icon-->
<!--                                  size="24px"-->
<!--                                  color="#919EAB"-->
<!--                                  class="mr-1"-->
<!--                                >-->
<!--                                  add-->
<!--                                </v-icon>-->

<!--                                Add Extension-->
<!--                              </v-btn>-->
<!--                            </template>-->
<!--                          </v-col>-->
                        </v-list-item-subtitle>
                      </v-list-item-content>
                  </div>
                </v-col>
              </v-row>

              <v-row
                v-if="$store.state.auth.currentUser.role === 'User'"
                class="mx-n5"
              >
                <v-col
                  cols="12"
                  md="12"
                  class="pb-1 pt-0"
                >
                  <v-divider
                    class="hl-724"
                  ></v-divider>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                  class="pb-1"
                >
                  <div class="pa-0 text-uppercase fz-12 font-weight-semi-bold">Recordings</div>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                  class="pa-0"
                >
                  <template v-for="(vm, vmIndex) in recordingArray">
                    <v-divider
                      :key="vmIndex + 'v-divider--recording'"
                      v-if="vmIndex"
                    ></v-divider>
                    <track-item
                      :key="vmIndex + 'track'"
                      :value="vm"
                      :isView="'HistoryComponent'"
                      class="px-4"
                    />
                  </template>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                  class="py-0"
                >
                  <v-col
                    cols="12"
                    md="12"
                    class="pa-0"
                  >
                    <v-divider
                      class="hl-724"
                    ></v-divider>
                  </v-col>
                </v-col>
              </v-row>

<!--              <v-row class="mx-n5 mb-n5">-->
<!--                <v-btn-->
<!--                  text-->
<!--                  tile-->
<!--                  link-->
<!--                  class="text-none fill-width"-->
<!--                  color="link"-->
<!--                  height="50px"-->
<!--                  @click="recordingExpand = !recordingExpand"-->
<!--                >-->
<!--                  Show all-->

<!--                  <v-icon-->
<!--                    color="regular-text"-->
<!--                    :class="{ 'button__icon&#45;&#45;active': recordingExpand }"-->
<!--                  >-->
<!--                    expand_more-->
<!--                  </v-icon>-->
<!--                </v-btn>-->
<!--              </v-row>-->
            </v-form>
          </v-card>
        </v-col>

<!--        <v-col-->
<!--          cols="12"-->
<!--          md="12"-->
<!--          v-if="$store.state.auth.currentUser.role !== 'company_admin'"-->
<!--        >-->
<!--          <v-row>-->
<!--            <v-col-->
<!--              cols="12"-->
<!--              md="3"-->
<!--              class="px-0 mt-3"-->
<!--            >-->
<!--              <h2 class="fz-16 font-weight-semi-bold">Account settings</h2>-->
<!--              <p class="fz-14 mt-2" style="color: #637381;">Manage your account and work times.</p>-->
<!--            </v-col>-->

<!--            <v-col-->
<!--              cols="12"-->
<!--              md="9"-->
<!--              class="px-0"-->
<!--            >-->
<!--              <v-row>-->
<!--                <v-col-->
<!--                  cols="12"-->
<!--                  md="12"-->
<!--                  class="d-flex justify-end align-start"-->
<!--                >-->
<!--                  <v-card-->
<!--                    class="pa-5"-->
<!--                    max-width="514px"-->
<!--                    width="100%"-->
<!--                  >-->
<!--                    <v-row>-->
<!--                      <v-col-->
<!--                        cols="12"-->
<!--                        md="6"-->
<!--                        class="fz-14 d-flex align-center"-->
<!--                      >-->
<!--                        Close this account-->
<!--                      </v-col>-->

<!--                      <v-col-->
<!--                        cols="12"-->
<!--                        md="6"-->
<!--                        class="text-right"-->
<!--                      >-->
<!--                        <v-btn-->
<!--                          class="violet-button"-->
<!--                        >-->
<!--                          Close account-->
<!--                        </v-btn>-->
<!--                      </v-col>-->
<!--                    </v-row>-->
<!--                  </v-card>-->
<!--                </v-col>-->

<!--                <v-col-->
<!--                  cols="12"-->
<!--                  md="12"-->
<!--                  class="d-flex justify-end align-start"-->
<!--                >-->
<!--                  <v-card-->
<!--                    class="pa-5 overflow-hidden"-->
<!--                    max-width="514px"-->
<!--                    width="100%"-->
<!--                  >-->
<!--                    <v-form>-->
<!--                      <v-row>-->
<!--                        <v-col-->
<!--                          cols="12"-->
<!--                          md="12"-->
<!--                          class="pb-1"-->
<!--                        >-->
<!--                          <div class="fz-14">Work time</div>-->
<!--                        </v-col>-->

<!--                        <v-col-->
<!--                          cols="12"-->
<!--                          md="4"-->
<!--                          class="pt-0"-->
<!--                        >-->
<!--                          <v-select-->
<!--                            solo-->
<!--                            outlined-->
<!--                            v-model="workTime.days"-->
<!--                            menu-props="offsetY"-->
<!--                            :items="days"-->
<!--                            hide-details-->
<!--                            class="pa-0 fz-14"-->
<!--                          >-->
<!--                            <template v-slot:append>-->
<!--                              <v-icon size="15px">unfold_more</v-icon>-->
<!--                            </template>-->
<!--                          </v-select>-->
<!--                        </v-col>-->

<!--                        <v-col-->
<!--                          cols="12"-->
<!--                          md="4"-->
<!--                          class="pt-0"-->
<!--                        >-->
<!--                          <v-select-->
<!--                            solo-->
<!--                            outlined-->
<!--                            v-model="workTime.format"-->
<!--                            :items="formatRange"-->
<!--                            menu-props="offsetY"-->
<!--                            hide-details-->
<!--                            class="pa-0 fz-14"-->
<!--                          >-->
<!--                            <template v-slot:append>-->
<!--                              <v-icon size="15px">unfold_more</v-icon>-->
<!--                            </template>-->
<!--                          </v-select>-->
<!--                        </v-col>-->

<!--                        <v-col-->
<!--                          cols="12"-->
<!--                          class="pt-0"-->
<!--                        >-->
<!--                          <v-row>-->
<!--                            <v-col-->
<!--                              cols="12"-->
<!--                              md="8"-->
<!--                              class="pt-0"-->
<!--                            >-->
<!--                              <v-menu-->
<!--                                v-if="workTime.format === 'From'"-->
<!--                                v-model="menuFrom"-->
<!--                                :close-on-content-click="false"-->
<!--                                :nudge-right="40"-->
<!--                                transition="scale-transition"-->
<!--                                offset-y-->
<!--                                min-width="auto"-->
<!--                              >-->
<!--                                <template v-slot:activator="{ on, attrs }">-->
<!--                                  <v-text-field-->
<!--                                    v-model="date"-->
<!--                                    label="Select time"-->
<!--                                    prepend-icon="mdi-calendar"-->
<!--                                    readonly-->
<!--                                    v-bind="attrs"-->
<!--                                    v-on="on"-->
<!--                                  ></v-text-field>-->
<!--                                </template>-->
<!--                                <v-date-picker-->
<!--                                  v-model="date"-->
<!--                                  @input="menuFrom = false"-->
<!--                                ></v-date-picker>-->
<!--                              </v-menu>-->

<!--                              <v-menu-->
<!--                                v-if="workTime.format === 'Between'"-->
<!--                                v-model="menuBetween"-->
<!--                                ref="menuBetween"-->
<!--                                :close-on-content-click="false"-->
<!--                                :return-value.sync="date"-->
<!--                                transition="scale-transition"-->
<!--                                offset-y-->
<!--                                min-width="auto"-->
<!--                              >-->
<!--                                <template v-slot:activator="{ on, attrs }">-->
<!--                                  <v-text-field-->
<!--                                    v-model="dates"-->
<!--                                    label="Select time"-->
<!--                                    prepend-icon="mdi-calendar"-->
<!--                                    readonly-->
<!--                                    v-bind="attrs"-->
<!--                                    v-on="on"-->
<!--                                  ></v-text-field>-->
<!--                                </template>-->
<!--                                <v-date-picker-->
<!--                                  v-model="dates"-->
<!--                                  range-->
<!--                                >-->
<!--                                </v-date-picker>-->
<!--                              </v-menu>-->
<!--                            </v-col>-->
<!--                          </v-row>-->
<!--                        </v-col>-->

<!--                        <v-col-->
<!--                          cols="12"-->
<!--                          md="12"-->
<!--                          class="pt-0"-->
<!--                        >-->
<!--                          <v-btn-->
<!--                            text-->
<!--                            class="text-none pa-0"-->
<!--                            @click="addTime"-->
<!--                          >-->
<!--                            <v-icon size="20px" color="#919EAB" class="mr-1">add</v-icon>-->
<!--                            Add time-->
<!--                          </v-btn>-->
<!--                        </v-col>-->

<!--                        <v-col-->
<!--                          cols="12"-->
<!--                          md="12"-->
<!--                          class="py-0"-->
<!--                        >-->
<!--                          <v-divider class="hl-724"></v-divider>-->
<!--                        </v-col>-->

<!--                        <v-col-->
<!--                          cols="12"-->
<!--                          md="12"-->
<!--                          class="d-flex"-->
<!--                        >-->
<!--                          <v-checkbox-->
<!--                            v-model="vacationHoliday"-->
<!--                            :value="vacationHoliday"-->
<!--                            class="ma-0 pa-0 checkbox d-flex"-->
<!--                          ></v-checkbox>-->

<!--                          <div class="fz-14 ml-2">Going on vacation/holiday?</div>-->
<!--                        </v-col>-->
<!--                      </v-row>-->
<!--                    </v-form>-->
<!--                  </v-card>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--        </v-col>-->
      </v-row>
    </v-card>
  </div>
</template>

<script>
import TrackItem from '../components/TrackItem'
import { mapGetters, mapActions } from 'vuex'
import { getBase64 } from '@/_helpers'

export default {
  name: 'MyProfile',
  components: { TrackItem },
  data: () => ({
    isFrom: false,
    isBetween: false,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dates: [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)],
    menuFrom: false,
    menuBetween: false,
    valid: false,
    form: {
      avatar: '',
      firstname: '',
      lastname: '',
      text: '',
      country: '',
      contacts: {
        phone: [
          {
            name: '',
            extensions: []
          }
        ],
        email: [
          {
            name: ''
          }
        ]
      }
    },
    loading: false,
    trackColor: 'grey lighten-2',
    recordingExpand: false,
    recordings: [
      {
        recordPlay: true,
        date: '22 Mar 2017',
        time: '12:46AM',
        file: '/CafeDelMar.mp3',
        duration: 43
      },
      {
        recordPlay: false,
        date: '13 Apr 2017',
        time: '02:52AM',
        file: '/CafeDelMar.mp3',
        duration: 43
      },
      {
        recordPlay: false,
        date: '09 Oct 2017',
        time: '09:36PM',
        file: '/CafeDelMar.mp3',
        duration: 43
      },
      {
        recordPlay: false,
        date: '24 Dec 2017',
        time: '11:21PM',
        file: '/CafeDelMar.mp3',
        duration: 43
      },
      {
        recordPlay: false,
        date: '07 Sep 2017',
        time: '08:43PM',
        file: '/CafeDelMar.mp3',
        duration: 43
      },
      {
        recordPlay: false,
        date: '24 Dec 2017',
        time: '11:21PM',
        file: '/CafeDelMar.mp3',
        duration: 43
      },
      {
        recordPlay: false,
        date: '07 Sep 2017',
        time: '08:43PM',
        file: '/CafeDelMar.mp3',
        duration: 43
      },
      {
        recordPlay: false,
        date: '24 Dec 2017',
        time: '11:21PM',
        file: '/CafeDelMar.mp3',
        duration: 43
      },
      {
        recordPlay: false,
        date: '07 Sep 2017',
        time: '08:43PM',
        file: '/CafeDelMar.mp3',
        duration: 43
      }
    ],
    days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Weekdays'],
    formatRange: ['Between', 'From'],
    workTime: {
      days: 'Weekdays',
      format: 'Between',
      range: []
    },
    vacationHoliday: false,
    prevPage: ''
  }),
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.prevPage = from.path
    })
  },
  computed: {
    fullName () {
      return !this.loading ? this.form.firstName + ' ' + this.form.lastName : ''
    },
    recordingArray () {
      return this.recordingExpand ? this.recordings : this.recordings.slice(0, 5)
    },
    ...mapGetters([
      'currentUser',
      'userImage'
    ]),
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  created () {
    this.getData()
  },
  methods: {
    ...mapActions([
      'getAccountData',
      'getAccountImage'
    ]),
    addTime () {
      console.warn('Method not configured!')
    },
    async getData () {
      try {
        this.loading = true

        const { data } = await this.getAccountData(this.$store.state.auth.currentUser.role)

        this.form = {
          ...data,
          link: this.userImage ? this.userImage : require('../assets/default-user-image.jpg'),
          contacts: {
            phone: [
              {
                name: data.userPhoneNumber,
                extensions: []
              }
            ],
            email: [
              {
                name: data.email
              }
            ]
          }
        }

        await this.getAccountImage({ responseType: 'arraybuffer' })
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.card-header  {
  position: relative;
  &::after {
    content: '';
    height: 1px;
    left: -20px;
    bottom: -10px;
    position: absolute;
    width: calc(100% + 40px);
    border-bottom: 1px solid #C7C7C7;
  }
}
</style>
